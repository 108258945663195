import EventEmitter from 'events';

const initialState = {
  wpm: 25,
  fwpm: 10,
  freq: 650,
  time_to_guess: 2500,
  limit: 30,
};
const EVENT_NAME = 'SETTINGS_STORE_UPDATE';
const g_store = new EventEmitter();
g_store.state = initialState;

export {
  Subscribe,
  Unsubscribe,
  Settings,
  UpdateSettings,
  getState,
  setState,
};
function getState() {
  return g_store.state;
}
function setState(data) {
  g_store.state = Object.assign({}, g_store.state, data);
}
function UpdateSettings(settings) {
  g_store.state = Object.assign({}, g_store.state, settings);
  _update();
}
function Settings() {
  return g_store.state;
}
function Subscribe(fn) {
  g_store.addListener(EVENT_NAME, fn);
};
function Unsubscribe(fn) {
  g_store.removeListener(EVENT_NAME,fn);
};
function _update() {
  g_store.emit(EVENT_NAME);
};
