import EventEmitter from 'events';
import * as _ from 'underscore';
import * as DataStore from './data';
import * as SettingsStore from './settings';
import * as ScoreStore from './score';

const key = 'wtb_data';
const _initialState = {};
const EVENT_NAME = 'SAVE_DATA_EVENT';
const g_store = new EventEmitter();
g_store.state = _initialState;

export {
  save,
  getAllData,
  init,
};
function save() {
  const data_store = _.pick(DataStore.getState(),['data_list']);
  const stats = _.pick(ScoreStore.getState(), ['missed_map', 'limit']);
  const data = {
    settings_store: SettingsStore.getState(),
    score_store: stats,
    data_store: data_store,
  };
  localStorage.setItem(key, JSON.stringify(data));
}
function getAllData() {
  let state = localStorage.getItem(key) || false;
  if (state) {
    state = JSON.parse(state);
  } else {
    state = Object.assign({}, _initialState);
  }
  return state;
}
function load(){
  console.log('load called');
  let data = localStorage.getItem(key) || false;
  if(data) {
    data = JSON.parse(data);
    DataStore.setState(data.data_store);
    SettingsStore.setState(data.settings_store);
    ScoreStore.setState(data.score_store);
  } else {
    data = {};
  }
}
function init() {
  load();
  DataStore.Subscribe(save);
  SettingsStore.Subscribe(save);
  ScoreStore.Subscribe(save);
}