import { Component } from 'react';
import {
  Subscribe,
  Unsubscribe,
  Stats,
} from '../store/score';
import '../App.css';
import { ScoreBox } from './scorebox';
  
export class ScoreDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      score: 0,
      correct: 0,
    };
    this._update = this._update.bind(this);
  }
  componentDidMount() {
    Subscribe(this._update);
  }
  componentWillUnmount() {
    Unsubscribe(this._update);
  }
  _update() {
    let s = Stats();
    this.setState(s);
  }
  render() {
    return (
      <div className="row" style={{height: '100px'}}>
        <div className="four columns">
          <ScoreBox name="SCORE">{this.state.score}%</ScoreBox>
        </div>
        <div className="four columns">
          <ScoreBox name="TOTAL">{this.state.total}</ScoreBox>
        </div>
        <div className="four columns">
        <ScoreBox name="CORRECT">{this.state.correct}</ScoreBox>
        </div>
      </div>
    );
  }
}