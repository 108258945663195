import EventEmitter from 'events';

// const data_list = ["a","b", "c", "d", "e", "f", "g", "h", "i", "k", "l", "m", "n", "o", "p", "r", "s", "t", "u", "v", "w","y"];
// const data_list = ["a","d","e","h","i","k","l","m","n","o","r","s","t"];
// const data_list = ["d","k","r","s","w","h","i","e"];
const data_list = ["f","p","g","w","b","e","t","l"];
// a,b,c,d,e,f,g,w,t,o,p,l
// a,b,c,d,e,k,f,g,w,t,o,m,p,l,i,s,n,h,y,v,u
const initialState = {
  data_list: data_list,
  number_list: [],
  index: 0,
  in_flight: false,
  item_in_flight: false,
  call_signs_only: false,
};

const Events = {
  EVENT_NAME: 'DATA_STORE_UPDATE',
  EXPIRE_ITEM: 'LIST_EXPIRE_ITEM',
  NEW_ITEM: 'NEW_ITEM',
};

const g_store = new EventEmitter();
g_store.state = initialState;

export {
  Events,
  RequestItem,
  StartTimer,
  Subscribe,
  SubmitAnswer,
  Unsubscribe,
  List,
  Update,
  getState,
  setState,
  CancelTimer,
};
function setState(data) {
  g_store.state = Object.assign({}, g_store.state, data);
}
function List() {
  return [...g_store.state.data_list];
}
function Update({list,call_signs}) {
  call_signs = call_signs ? call_signs : false;
  const number_list = extractNumbers(list);
  g_store.state = Object.assign({}, g_store.state, {
    data_list: list, 
    number_list: number_list,
    call_signs_only: call_signs,
  });
  g_store.state.index = 0;
  _update();
}
function StartTimer(ms) {
  g_store.state.t = setTimeout(() => {
    g_store.state.in_flight=false;
    expire();
  }, ms);
}
function CancelTimer() {
  clearTimeout(g_store.state.t);
  g_store.state.in_flight=false;
}
function randomIntRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
function extractNumbers(list) {
  let number_list = [...list];
  number_list = number_list.filter(item => item.match(/\d/g));
  if(number_list.length === 0) {
    number_list = [1,2,3,4,5,6,7,8,9,0];
  }
  const k = {}
  number_list = number_list.map(item => k[item]=true);
  return Object.keys(k);
}
function generateCallsign() {
  const prefixLength = randomIntRange(1,2);
  const postfixLength = randomIntRange(1,3);
  const prefix = generateLetterSequence(prefixLength);
  const postfix = generateLetterSequence(postfixLength);
  const idx = randomIntRange(0,g_store.state.number_list.length-1);
  return prefix + g_store.state.number_list[idx] + postfix;
}
function generateLetterSequence(length) {
  let item = "";
  for (let index = 0; index < length; index++) {
    const arrIndex = randomIntRange(0,g_store.state.data_list.length-1);
    const letter = g_store.state.data_list[arrIndex];
    item = item.concat(letter);
  }
  return item;
}
function RequestItem(length) {
  if(g_store.state.in_flight) {
    return false;
  }
  let item = "";
  if(g_store.state.call_signs_only) {
    item = generateCallsign();
  } else {
    item = generateLetterSequence(length);
  }
  g_store.state.in_flight = true;
  g_store.state.item_in_flight = item;
  publish(item);
}
function publish(item) {
  g_store.emit(Events.NEW_ITEM,{eventType:Events.NEW_ITEM, arg:item});
}
function SubmitAnswer(a) {
  if(!g_store.state.in_flight) { // if nothing is in flight -- nothing to guess
    return {is_correct: false, letter: ''};
  }
  g_store.state.in_flight=false;
  clearTimeout(g_store.state.t);
  if(a === g_store.state.item_in_flight) {
    return {is_correct: true, letter: g_store.state.item_in_flight};
  } else {
    return {is_correct: false, letter: g_store.state.item_in_flight};
  }
}
function expire() {
  g_store.emit(Events.EXPIRE_ITEM, {eventType: Events.EXPIRE_ITEM, arg:g_store.state.item_in_flight});
}
function getState() {
  return g_store.state;
}
function Subscribe(fn) {
  g_store.addListener(Events.NEW_ITEM,fn);
  g_store.addListener(Events.EXPIRE_ITEM,fn);
};
function Unsubscribe(fn) {
  g_store.removeListener(Events.EVENT_NAME,fn);
};
function _update() {
  g_store.emit(Events.EVENT_NAME);
};
