import { Component } from 'react';
import * as DataStore from '../store/data';
import * as SettingsStore from '../store/settings';
import * as ScoreStore from '../store/score';

export class SettingsBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wpm: 25, 
      fwpm: 5, 
      freq: 650,
      data: "a,b,c",
      time_to_guess: 2500,
      multiletter: 1,
      limit: 30,
      call_signs_only: false,
    };
    this.updateData = this.updateData.bind(this);
    this.dataChange = this.dataChange.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
  }
  componentDidMount() {
    DataStore.Subscribe(this.updateData);
    SettingsStore.Subscribe(this.updateSettings);
    this.updateData();
    this.updateSettings();
  }
  componentWillUnmount() {
    DataStore.Unsubscribe(this.updateData);
  }
  updateSettings() {
    const s = SettingsStore.Settings();
    this.setState(s);
  }
  updateData() {
    const new_list = DataStore.List().join(",");
    this.setState({data: new_list});
  }
  dataChange(event) {
    if(event.target.name === "data") {
      this.setState({data: event.target.value});
      const new_data = event.target.value.split(',');
      console.log(new_data);
      DataStore.Update({list: new_data, call_signs: this.state.call_signs_only});
    } else {
      this.setState({call_signs_only: event.target.value});
      const list = this.state.data.split(',');
      console.log('wtf',event.target.name, event.target.checked);
      DataStore.Update({list: list, call_signs: event.target.checked});
    }
  }
  fieldChange(e) {
    let val = e.target.value;
    if(e.target.type === "number") {
      val = Number(val);
    }
    this.setState(prevState => ({...prevState.item,  [e.target.name]: val }),() => {
      const {
        wpm,
        fwpm,
        freq,
        time_to_guess,
        multiletter,
        limit,
        call_signs_only,
      } = this.state;
      const settings = {wpm,fwpm,freq,time_to_guess,multiletter,limit,call_signs_only};
      SettingsStore.UpdateSettings(settings);
      ScoreStore.UpdateSettings({limit: this.state.limit});
    });

  }
  render() {

    return (
      <div className='settings-box'>
        <form>
          <fieldset>
            <legend>settings</legend>

            <label htmlFor="wpm">wpm:</label>
            <input id="freq" name='wpm' type="number" value={this.state.wpm} onChange={this.fieldChange}></input>

            <label htmlFor="time_to_guess">time to guess in ms</label> 
            <input id='time_to_guess' name='time_to_guess' type="number" value={this.state.time_to_guess} onChange={this.fieldChange}></input>

            <label htmlFor="fwpm">fwpm</label>
            <input id='fwpm' name='fwpm' type="number" value={this.state.fwpm} onChange={this.fieldChange} />

            <label htmlFor="freq">freq</label>
            <input id='freq' name='freq' type="number" value={this.state.freq} onChange={this.fieldChange} />

            <label htmlFor="limit">round limit</label>
            <input id='limit' name='limit' type="number" value={this.state.limit} onChange={this.fieldChange} />

            <label htmlFor="data">data set</label> 
            <input id="data" name='data' className='data-input' type="text" value={this.state.data} onChange={this.dataChange} />

            <label htmlFor="multiletter">multi-letter</label> 
            <input id="multiletter" name='multiletter' type="number" value={this.state.multiletter} onChange={this.fieldChange} />

            <label htmlFor="callsigns">call signs only</label> 
            <input id="callsigns" name='callsigns' type="checkbox" value={this.state.call_signs_only} onChange={this.dataChange} />

          </fieldset>
        </form>
      </div>
    );
  }
}