import '../App.css';

export const ScoreBox = ({name,highlight,children}) => {
  let boxClass="score-box " + highlight;
  return (
    <div className={boxClass}>
      <h6 className="score-header">{name}</h6>
      <div className="score-value">{children}</div>
    </div>
  );
}
