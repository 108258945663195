import EventEmitter from 'events';

const initialState = {
    correct: 0,
    total: 0,
    missed_map: {},
    score: 0,
    limit: 30,
    done: false,
};
const EVENT_NAME = 'SCORE_STORE_UPDATE';
const g_store = new EventEmitter();
g_store.state = initialState;

export {
  Add,
  Stats,
  Score,
  Subscribe,
  Unsubscribe,
  getState,
  setState,
  UpdateSettings,
  Reset,
};
function setState(data) {
  g_store.state = Object.assign({}, g_store.state, data);
}
function Add(correct,letter_str) {
  console.log('add');
  let missed_map = g_store.state.missed_map;
  if(!correct) {
    const letter_list = letter_str.split("");
    for (let index = 0; index < letter_list.length; index++) {
      const letter = letter_list[index];
      let missed_letter_val = missed_map[letter] ? missed_map[letter]+1 : 1;
      missed_map[letter] = missed_letter_val;
    }
  }
  let val = g_store.state.correct;
  if(correct){
    val = val + 1;
  }
  g_store.state = Object.assign({}, 
    g_store.state, {
      total: g_store.state.total+1,
      correct: val,
      missed_map: missed_map,
    }
  );
  console.log('here...:');
  console.log(g_store.state);
  if(g_store.state.total === g_store.state.limit) {
    _update({done:true});
    g_store.state.total = 0;
    g_store.state.correct = 0;
  } else {
    _update({done:false});
  }
  console.log(g_store.state.missed_map);
}
function UpdateSettings(settings) {
  g_store.state = Object.assign({}, g_store.state, settings);
}
function Score() {
  return ((g_store.state.correct / g_store.state.total) * 100).toFixed(2);
}
function Reset() {
  g_store.state = Object.assign({}, initialState, {missed_map: {}});
  _update({done:true});
}
function Stats() {
  let score = Score();
  score = isNaN(score) ? 0 : score;
  return Object.assign({}, g_store.state, {score: score});
}
function Subscribe(fn) {
  g_store.addListener(EVENT_NAME, fn);
};
function Unsubscribe(fn) {
  g_store.removeListener(EVENT_NAME,fn);
};
function _update(arg) {
  g_store.emit(EVENT_NAME,arg);
};
function getState() {
  return g_store.state;
}
